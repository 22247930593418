import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import { useNavigate,useParams } from "react-router-dom";
import axios from 'axios';
import ajouter from '../images/ajouter.png';
import background from '../images/bg3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {MenuItem ,TextField, Stack, Fab, Autocomplete , Container, Tooltip , IconButton, Button} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add.js';
import SendIcon from '@mui/icons-material/Send';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


var nextId = 0;
var BACKEND_URL = 'https://backend.bt-support.net/api/';

export default function FormAppro() {


    const [entrepots, setEntrepots] = useState([]);
    const [selectedEntrepot, setSelectedEntrepot] = useState('');
    const [client, setClient] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [produit, setProduit] = useState([]);
    const [selectedProduit, setSelectedProduit] = useState('');
    const [selectedTypeSorties, setSelectedTypesSorties] = useState('');
    const [prixAchat, setPrixAchat] = useState([]);
    const [quantite, setQuantite] = useState([]);
    const { compte_id } = useParams();
    

    const [listproduit, setListProduits] = useState([]);
    
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [backendErrorMessageProduit, setBackendErrorMessageProduit] = useState('');
    const [backendErrorMessageSortie, setBackendErrorMessageSortie] = useState('');
    const [width, setWindowWidth] = useState(0);
    const clientOptions = [...client, { id: -1, nomClient: "Tiers" }];


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};


    const handleProduitChange = (newValue) => {
      setSelectedProduit(newValue);
    };
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };
    

    const request = async () => {
      try{
      await axios.get(`${BACKEND_URL}entrepots/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setEntrepots(data);

      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}client/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setClient(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}produit/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setProduit(data);
      }).catch ((error) =>
      {
          setBackendErrorMessageProduit(error.response.data.error);
      });

    } catch (error) {
      console.log(error)
    }
  }

    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
     return () => 
       window.removeEventListener('resize',updateDimensions);
      }, []);


      const handleSubmit = async (e) => {
        
        //e.preventDefault();
        try {
          // Effectuer la première requête pour créer un nouvel approvisionnement
          if(selectedClient=='' || selectedDate=='' || selectedEntrepot=='' || selectedTypeSorties=='')
          {
            setBackendErrorMessage('Veuillez entrez les informations de la sortie')
          }
          else{
            await axios.post(`${BACKEND_URL}releveSortie/create/${compte_id}`, {
                    entrepot_id: selectedEntrepot,
                    dateSortie: selectedDate,
                    client_id: selectedClient,
                    typeSortie: selectedTypeSorties
                  }).catch(error => {
                    console.error('Erreur de requête :', error);
                  });

          // Récupérer le résultat de la première requête
          console.log();
        
          // Effectuer la deuxième requête pour chaque produit dans listproduit
          for (const produit of listproduit) {
            await axios.post(`${BACKEND_URL}sortieStock/create/${compte_id}`, {
              libelleProduit: produit.libelleProduit,
              quantite: produit.quantite,
            });
          }
        
          console.log('Toutes les requêtes ont été effectuées avec succès');
          navigate(`/sorties/${compte_id}`);
          setBackendErrorMessage('')

          }
          
        } catch (error) {
          console.error('Une erreur s\'est produite :', error);
          setBackendErrorMessage(error.response.data.error);
        }
      };

      const Deleterequest = async (entreId) => {
        if (!window.confirm('Voulez-vous vraiment supprimer?')) {
          return;
        }    
        let updatedProduits = listproduit.filter(produit => produit.id !== entreId);

         // Mettez à jour l'état avec le nouveau tableau
          setListProduits(updatedProduits);
          window.alert('Element supprimer avec succès')
    }

    
    return (
        <div style={{
              //backgroundColor: '#EFECEC', 
              backgroundImage: `url(${background})` ,
              backgroundSize: 'cover',
              }}>
        <Nav compte_id={compte_id} />
        <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}}   onClick={() => {handleLinkClick(`sorties/${compte_id}`)}} >
                    RETOUR                      
            </Button>
        <div className="container mt-5" style = {{maxWidth: "80%", backgroundColor:  '', borderRadius: 20, }}>
        <div className='row'>
        <div className="col-sm-6" style = {{borderTopLeftRadius: 20 ,borderEndStartRadius: 20 , backgroundColor:  '#ffffff', padding: 20}}>
            <Stack direction={'column'}
                 alignItems="center"
                 spacing={{ xs: 1, sm: 1, md: 4 }}>
                  <Stack>
                      <Container>
                        <h4>Informations relevée de sortie</h4>
                      </Container>
                      {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>} 
                  </Stack>                  
                  
                  <Stack direction={'column'} spacing={1}>
                  <Container >
                    <label htmlFor="nom" className="form-label" style={{fontWeight: 600}}>Libéllé de l'entrepot</label>
                    <Autocomplete
                        id="combo-box-demo"
                        options={entrepots}
                        getOptionLabel={(option) => option && option.libelleEntre ? option.libelleEntre : ''}
                        style={{width: width<800 ?  250 : (width*30)/100}}
                        value={selectedEntrepot.value}
                        isOptionEqualToValue={(option, value) => option.libelleEntre === value}
                        onChange= {(event, newValue) => {
                          setSelectedEntrepot(newValue ? newValue.id:'');
                          console.log(newValue ? newValue.id:'');
                          // Nouvelle valeur sélectionnée
                        }
                      }
                        renderInput={(params) => (
                          <TextField {...params} label="Sélectionnez un entrepot" />
                        )}
                      />
                  </Container>
                  <Container >
                    <label htmlFor="text" className="form-label"  style={{fontWeight: 600}}>Libéllé client</label>
                    <Autocomplete
                        id="combo-box-demo"
                        options={clientOptions}
                        getOptionLabel={(option) => option.nomClient}
                        style={{width: width<800 ?  250 :(width*30)/100}}
                        value={selectedClient.value}
                        onChange={(event, newValue) => {
                          setSelectedClient(newValue ? newValue.id:'');
                          console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                          
                          console.log(selectedClient) 
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Sélectionnez un client" />
                        )}
                      />
                    </Container>
                    <Container >
                    <label htmlFor="text" className="form-label"  style={{fontWeight: 600}}>Type de la sortie</label>
                      <TextField type='text' 
                            label='Sélectionnez un type'
                            value={selectedTypeSorties}
                            style={{width: width<800 ?  250 : (width*30)/100}}
                            select
                            onChange={(e)=> {
                              setSelectedTypesSorties(e.target.value)
                            }}
                        >
                            <MenuItem key={'Vente'} value={'Vente'}>
                            {'Vente'}
                          </MenuItem>
                          <MenuItem key={'Casse'} value={'Casse'}>
                            {'Casse'}
                          </MenuItem>
                        </TextField>
                      </Container>
                    <Container>
                      <Stack direction={'column'} spacing={1}>
                              <label htmlFor="date" className="form-label" style={{ fontWeight: 600 }}>
                              Date
                            </label>
                            <TextField type='date' 
                            value={selectedDate}
                            style={{width: width<800 ?  250 :(width*30)/100}}
                            onChange={(e) => {
                              setSelectedDate(e.target.value)
                              console.log(quantite)
                            }
                            }>

                            </TextField>
                      </Stack>  
                                      
                    </Container>
                  </Stack>
                  <Button variant="contained" endIcon={<SendIcon />} onClick={() => {handleSubmit()}} disabled={!listproduit.length > 0} style={{fontWeight: 600}}>
                    Terminer
                  </Button>
                  
                </Stack>
                </div>
                <div className="col-sm-6" style={{backgroundColor: '#0D9276', borderTopRightRadius: 20 ,borderEndEndRadius: 20 ,padding: 20}}>
                <Stack direction={'column'}
                 alignItems="center"
                 spacing={{ xs: 1, sm: 2, md: 4 }}>
                  <Stack>
                  <Container>
                      <h4 style={{color:'white'}}>Informations produits</h4>
                  </Container>
                  </Stack>
                  <Stack direction={'column'} spacing={1}> 
                  {backendErrorMessageProduit && <p style={{ color: 'red' }}>{backendErrorMessageProduit}</p>}            
                      <Container >
                      <label htmlFor="nom" className="form-label" style={{fontWeight: 600, color: 'white'}}>
                        Libéllé du produit</label>
                          <Autocomplete
                              id="combo-box-demo"
                              options={produit}
                              getOptionLabel={(option) => option.nomProd}
                              size='medium'
                              style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}
                              value={selectedProduit.value}
                              onChange={(event, newValue) => {
                                setSelectedProduit(newValue ? newValue.nomProd:'');
                                console.log(newValue ? newValue.nomProd:''); // Nouvelle valeur sélectionnée
                                
                                console.log(selectedClient)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un produit"  />
                              )}
                            />
                      </Container>
                  </Stack>

                    <Stack direction={'column'} spacing={1}>
                          <label htmlFor="integer" className="form-label" style={{ fontWeight: 600 , color: 'white'}}>
                                  Quantité
                          </label>
                          <TextField
                              type="number"
                              className="form-control"
                              id="number"
                              style={{width: width<800 ?  250 :(width*30)/100}}
                              value={quantite}
                              onChange={(e) => {
                                setQuantite(e.target.value)
                              
                              console.log(selectedProduit)
                              }
                              }
                            />
                    </Stack>
                  <Stack>
                      <Fab variant="extended" size="medium" color="primary"
                        onClick={() => {
                          if(selectedProduit=='' || quantite==null)
                          {
                            setBackendErrorMessageProduit('Veuillez remplir tous les champs')
                          }
                        else {
                          setListProduits([
                            ...listproduit,
                            {id: nextId++, libelleProduit: selectedProduit, quantite: quantite }
                          ]);
                          console.log(listproduit) 
                        }
                      }
                    }
                        style={{width: width<800 ?  250 :(width*15)/100 , fontWeight: 600}}>
                          <AddIcon sx={{ mr: 1 }} />
                          Ajouter
                      </Fab>
                  </Stack>
                </Stack>
                <div className="container mt-4 d-flex justify-content-center" >
                      <div>
                      <h5 style={{color: 'white'}}>Produits</h5> 
                      </div>
                
                    <table class="table" style={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)'}}>
                          <thead>
                            <tr>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Numéro</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Produit</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Quantité</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Action</th>
                            </tr>
                          </thead>
                          {Array.isArray(listproduit) ? (
                          <tbody>
                          {listproduit.map((row, index) => (
                            <tr>
                              <th scope="row" style={{backgroundColor: 'white', fontWeight: 600}}>{index + 1 }</th>
                              <td style={{backgroundColor: 'white', fontWeight: 600}}>{row.libelleProduit}</td>
                              <td style={{backgroundColor: 'white', fontWeight: 600}}>{row.quantite}</td>
                              <td style={{backgroundColor: 'white', fontWeight: 600}}>
                                <Tooltip title="Delete">
                                    <IconButton style={{ color: '#D24545' }}  onClick={() => {
                                           Deleterequest(row.id)
                                          }}>                                        
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>                               
                              </td>
                            </tr>
                              ))}
                          </tbody>
                           ) : (
                            <p>{backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}</p>
                        )}
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}