import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Stack, Tooltip, Container} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import openCom from '../images/openCom.png';
import carDelivery from '../images/carDelivery.png';

export default function ConfigurationIntervention() {
  const navigate = useNavigate();
  const { compte_id } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);

      return () => {       
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'column'}
                     sx={{margin: 3}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20}}>
                    Configuration des interventions
                  </Stack>
                  <Stack direction={windowWidth>700?'row':'column'}
                        alignItems="right"
                        sx={{margin: 2}}
                        spacing={{ xs: 1, sm: 1, md: 4 ,}}>

                          <Stack>
                            <Tooltip title='Type de contrat'  onClick={()=> {navigate(`type-contrat/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',minWidth:120 , backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <LockIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Type contrat
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Type de client' onClick={()=> {navigate(`type-client/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)', minWidth:120 ,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <AccountCircleIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Type de client
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>                          
                  </Stack>
            </Stack>
           
        </div>
    );
}