import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress ,Box, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import * as XLSX from 'xlsx'
import Fuse from 'fuse.js';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';
import B2 from '../images/B2.png';
import B from '../images/B.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';
import { Tab } from 'bootstrap';

const loadingImages = [
  B,
  T,
  techni,
  laptop,
  lock,
  hand,
  B2,     
  wallet
 // Ajoutez autant d'images que nécessaire
];


var BACKEND_URL = 'https://backend.bt-support.net/api/';
var countData=1;

export default function Client() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listClient, setListClient] = useState([]);
    const [listTypeClient, setListTypeClient] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [idClient, setIdClient] = useState('');
    const [nomClient, setnomClient] = useState('');
    const [typeClient, settypeClient] = useState('');
    const [codeClient, setcodeClient] = useState('');
    const [adresseClient, setadresseClient] = useState('');
    const [telClient, settelClient] = useState('');
    const [mailClient, setMailClient] = useState('');
    const [boitePostale, setBP] = useState('');
    const [rccm, setRccm] = useState('');
    const [regimeNormale, setRegimeNormale] = useState('');
    const [numeroIFU, setNumeroIFU] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listClient.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listClient.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };
    const { compte_id } = useParams();

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const handleExportExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(listClient);
    
        //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });
    
        // Mettre la première ligne en gras
        //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });
    
        XLSX.utils.book_append_sheet(wb, ws , 'Client');
    
        XLSX.writeFile(wb , 'Relevé des clients.xlsx');
    
      }


      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}client/${compte_id}`)
        .then(({data}) => {
            setListClient(data);
            console.log(listClient);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
        await axios.get(`${BACKEND_URL}typeClient/${compte_id}`)
        .then(({data}) => {
            setListTypeClient(data);
            console.log(listTypeClient);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    
    const ajoutClient = async (e) => {
      if(nomClient==''){
        setBackendErrorMessage('Veuillez entrez le nom du client')
      }
      else if(typeClient==null)
      {
        setBackendErrorMessage('Veuillez entrez le type du client')
      }
      else if(codeClient=='')
      {
        setBackendErrorMessage('Veuillez entrez le code du client')
      }
      else if(telClient=='')
      {
        setBackendErrorMessage('Veuillez entrez le contact du client')
      }
      else if(adresseClient=='')
      {
        setBackendErrorMessage('Veuillez entrez l\'adresse du client')
      }
      else{

      await axios.post(`${BACKEND_URL}client/create/${compte_id}`, {
                    nomClient: nomClient,
                    typeClient:typeClient,
                    codeClient:codeClient,
                    telClient:telClient,
                    adresseClient:adresseClient,
                    mailClient:mailClient,
                    boitePostale: boitePostale,
                    rccm: rccm,
                    numeroIFU: numeroIFU,
                    regimeNormale: regimeNormale,
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                  setBackendErrorMessage('')
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
    }

    };

    

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}client/destroy/${approId}/${compte_id}`);
          console.log(`Approvisionnement avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    
        const [searchQuery, setSearchQuery] = useState('');
        // Configuration de Fuse.js
    const fuseOptions = {
     keys: ['nomClient', 'codeClient', 'typeClient'], // Les champs à rechercher
     includeScore: true,
    };

    // Initialiser un nouvel objet Fuse avec les options
    const fuse = new Fuse(listClient, fuseOptions);

    // Fonction pour gérer le changement de la recherche
    const handleSearchChange = (e) => {
     const query = e.target.value;
     setSearchQuery(query);
    
     // Effectuer la recherche et mettre à jour les résultats
     const results = fuse.search(query);
    
     if (query==''){
       //setApproData(results.map((result) => result.item));
       request()
     }
     else{
       setListClient(results.map((result) => result.item));
     }
   
    };
    
        const handlePrint = () => {
            const printWindow = window.open('', '_blank');
          
            const tableRows = listClient.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.nomClient}</td> 
                <td>${row.nomTypeClient}</td> 
                <td>${row.codeClient}</td> 
                <td>${row.telClient}</td> 
                <td>${row.adresseClient==null ? '':row.adresseClient}</td>                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des clients</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">
                    <h1>M2AGROUP</h1>
                    <p>Siege social 01 BP 6727 OUAGA 01</p>
                    <p>Téléphone BF :+226 71081010</p>
                </div>
                  <h2>Tableau des clients</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Nom</th>
                      <th>Type client</th>
                      <th>Code Client</th>
                      <th>Téléphone</th>
                      <th>Adresse</th>
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
        };
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 2000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 200);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
        
            return () => {
              clearTimeout(timer);
              clearInterval(interval);
              window.removeEventListener('resize', handleResize);
          };
        
          }, []);


  const navigate = useNavigate();
if(!loading){
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <div style={{marginRight: 'auto'}}><h3>Gestion des clients</h3></div>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() =>{}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() =>{}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      boxShadow: '0px 0px 5px #508D69',
                      backgroundColor:'white',
                      minWidth: windowWidth-150}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des clients
                  </Stack>
                  <TextField type="text" 
                          value={searchQuery} 
                          style={{marginBottom: 15, 
                            marginRight:'auto',
                           marginLeft:8}}
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                  <Stack alignItems='center'>                      
                    {windowWidth>700?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#508D69',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Client</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type client</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Code Client</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Adresse</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Actions</TableCell>                                            
                                    </TableRow>
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, color:'red'}}>{row.nomTypeClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.codeClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.telClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.adresseClient}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>   
                                                  <Stack direction={'column'}
                                                      spacing={{sm: 1}}                                                      
                                                  >
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} > 
                                                      <Tooltip title='Équipement'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/equipements/${compte_id}`)}} >
                                                          <DesktopWindowsIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                               
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-client/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                      </Stack>
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}}>                                                        
                                                    <Tooltip title='Contrat'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/contrats/${row.nomClient}/${compte_id}`)}} >
                                                          <InsertDriveFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Agence'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/agences/${compte_id}`)}} >
                                                          <BusinessIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                      </Stack>                                                    
                                                  </Stack>                                             
                                                                                                     
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300,maxWidth:450 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#508D69',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Client</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600,  backgroundColor: '#E3FEF7'}} colSpan={2}>Nom</TableCell>                                                                                        
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}} colSpan={2}>{countData+index}-{row.nomClient}</TableCell>                                                                                       
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Type client</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Code Client</TableCell>                                                                                       
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{color:'red'}}>{row.nomTypeClient}</TableCell>
                                            <TableCell align="center" style={{}}>{row.codeClient}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Adresse</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{}}>{row.telClient}</TableCell>
                                            <TableCell align="center" style={{}}>{row.adresseClient}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} colSpan={2} >Actions</TableCell>
                                    </TableRow>                                        
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}} colSpan={2} >                                               
                                                      <Tooltip title='Équipement'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/equipements/${compte_id}`)}} >
                                                          <DesktopWindowsIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                               
                                                    <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={()=>{navigate(`${row.id}/afficher-client/${compte_id}`)}} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                    </Tooltip>                          
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                                                                           
                                                    <Tooltip title='Contrat'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/contrats/${row.nomClient}/${compte_id}`)}} >
                                                          <InsertDriveFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Agence'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={()=>{navigate(`${row.id}/agences/${compte_id}`)}} >
                                                          <BusinessIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                                                                                                                                                                                                                     
                                            </TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                      ))}
                                                
                            </Table>
                    </TableContainer>
                    }
                    <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total {listClient.length}</h5> 
                        </div>
                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
            </Button> 
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du client"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >                            
                            <TextField type='text' 
                                label='Nom client'
                                value={nomClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setnomClient(e.target.value)
                                }}
                            ></TextField>
                            <Autocomplete
                              id="combo-box-demo"
                              options={listTypeClient}
                              getOptionLabel={(option) => option.nomTypeClient}
                              size='medium'
                              style={{minWidth:210}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={typeClient.value}
                              onChange={(event, newValue) => {
                                settypeClient(newValue ? newValue.id:'');
                                console.log(newValue ? newValue.id:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un type"  />
                              )}
                            />
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Code Client'
                                value={codeClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                  setcodeClient(e.target.value)
                                  console.log(typeClient)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Adresse client'
                                value={adresseClient}
                                onChange={(e)=> {
                                    setadresseClient(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Téléphone client'
                                value={telClient}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    settelClient(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Mail client'
                                value={mailClient}
                                onChange={(e)=> {
                                    setMailClient(e.target.value)
                                }}
                            ></TextField>
                            
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='RCCM'
                                value={rccm}
                                style={{marginBottom:8}}
                                onChange={(e)=> {
                                    setRccm(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Boite postale'
                                value={boitePostale}
                                onChange={(e)=> {
                                    setBP(e.target.value)
                                }}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1, marginBottom:3}}
                          >
                            <TextField type='text' 
                                label='Régime Normale'
                                style={{marginBottom:8}}
                                value={regimeNormale}
                                onChange={(e)=> {
                                    setRegimeNormale(e.target.value)
                                }}
                            ></TextField>
                            <TextField type='text' 
                                label='Numéro IFU'
                                
                                value={numeroIFU}
                                onChange={(e)=> {
                                    setNumeroIFU(e.target.value)
                                }}
                            ></TextField>                           
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutClient()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>        
        </div>
    );
    
    function nextPage(){
      if(firstIndex+5 < listClient.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
    else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress
            />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
  }