import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {CircularProgress,Autocomplete,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip} from '@mui/material';
import { Navigate, useNavigate,useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SecurityIcon from '@mui/icons-material/Security';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';


export default function ConfigurationRole() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [listRoles, setListRoles] = useState([]);   
    const [listCompte, setListCompte] = useState([]);    
    const [open, setOpen] = React.useState(false);
    const [nomRole, setNomRole] = useState('');
    const [nomCompte, setNomCompte] = useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogCompte, setOpenDialogCompte] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();

    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const handlePrintTable = () => {
        const printWindow = window.open('', '_blank');
        
        const tableRows = listRoles.map((row, index) => (
          `<tr>
            <td>${index + 1}</td>
            <td>${row.name}</td>
          </tr>`
        )).join('');
        
        // Utilisez la chaîne HTML générée pour construire le document HTML
        const htmlContent = `
          <html lang="fr">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Tableau des rôles</title>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body>
              <h2>Tableau des rôles</h2>
              <table>
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableRows}
                </tbody>
              </table>
            </body>
          </html>`;
        
        // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.print();
      };
    

      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        setNomRole('')
        setOpenDialog(false);
      };

      const handleClickOpenDialogCompte = () => {
        setOpenDialogCompte(true);
      };
    
      const handleCloseDialogCompte = () => {
        setBackendErrorMessage('')
        setOpenDialogCompte(false);
      };
      
      const ajoutRole = async (e) => {
        if(nomRole== null || nomRole=='')
        {
          setBackendErrorMessage('Veuillez entrez le nom du role')
        }
        else 
        {
          await axios.post(`${BACKEND_URL}roles/create/${compte_id}`, {
                    name: nomRole,
                  }).catch(error => {
                    console.error('Erreur de requête :', error);
        });
        request();
        handleCloseDialog()

        }
        
      };
      
  

    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}roles/${compte_id}`)
        .then(({data}) => {
            setListRoles(data);
            console.log(listRoles);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}roles/${compte_id}`)
        .then(({data}) => {
            setListRoles(data);
            console.log(listRoles);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
      } catch (error) {
        console.log(error)
      }
    }

    const Deleterequest = async (approId) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
        try {
          await axios.delete(`${BACKEND_URL}roles/destroy/${approId}/${compte_id}`);
          console.log(`Role avec l'ID ${approId} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          setOpen(true);
          request()
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression du role', error);
          // Ajoutez ici la gestion des erreurs
          setBackendErrorMessage(error.response.data.error);
          window.alert(error.response.data.error)
        }
    }

    const handleLinkClick = (link) => {
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };
    
        const handlePrint = () => {
          window.print();
        };

        useEffect(() => {
          try {
            request();
            
          } catch (error) {
            console.log(error)
          }
            const timer = setTimeout(() => {
              // Mettez à jour l'état pour arrêter le chargement après 3 secondes
              setLoading(false);
            }, 1000); // 3000 millisecondes = 3 secondes
        
            // Nettoyez le timer lorsque le composant est démonté
            const interval = setInterval(() => {
              setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
            }, 100);

            const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
        
          window.addEventListener('resize', handleResize);

            return () => {
              clearTimeout(timer);
              clearInterval(interval)
              window.removeEventListener('resize', handleResize);
          };
          }, []);

          const [loading, setLoading] = useState(true);
          const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id} />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}`)}} >
                        RETOUR                      
                </Button>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrintTable()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handlePrint()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                        marginLeft: 2, 
                        marginRight: 2, 
                        borderRadius: 5, 
                        minWidth:windowWidth-150,
                        boxShadow: '0px 0px 5px #50623A',
                        backgroundColor:'white'}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}} >
                    Liste des Roles
                  </Stack>
                  <Stack id="table-container">
                  {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth: windowWidth-200 }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#50623A',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={3}>Roles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Role</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>
                                            
                                    </TableRow>
                                        {listRoles.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{index+1}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.name}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>                                                                             
                                                    <Tooltip title={`Permissions du role ${row.name}`}>
                                                        <IconButton style={{ color: 'green' }} onClick={() => {navigate(`droit-acces/${row.id}/${compte_id}`)}} >
                                                          <SecurityIcon />
                                                        </IconButton>
                                                    </Tooltip>                         
                                                    <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>                                                 
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}}  startIcon={<AddIcon />}   onClick={() => handleClickOpenDialog()} >
                    Ajouter                      
                  </Button> 
            </Stack>   
            <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du role"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            <TextField type='text' 
                            label='Entrez le nom du role'
                            value={nomRole}
                            onChange={(e)=> {
                                setNomRole(e.target.value)
                            }}
                            ></TextField>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutRole()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogCompte}
                  onClose={handleCloseDialogCompte}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du compte"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listCompte}
                              getOptionLabel={(option) => option.pays}
                              size='medium'
                              style={{minWidth:250}}
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={nomCompte.value}
                              onChange={(event, newValue) => {
                                setNomCompte(newValue ? newValue.pays:'');
                                console.log(newValue ? newValue.pays:''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un compte"  />
                              )}
                            />                            
                          </Stack>                          
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajoutRole()}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialogCompte} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>       
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}