import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import { useNavigate,useParams } from "react-router-dom";
import axios from 'axios';
import ajouter from '../images/ajouter.png';

export default function FormProdSortie() {


    const [produit, setProduit] = useState([]);
    const [selectedProduit, setSelectedProduit] = useState('');
    const [quantite, setQuantite] = useState([]);
    const [backendErrorMessageProduit, setBackendErrorMessageProduit] = useState('');
    const [backendErrorMessageSortie, setBackendErrorMessageSortie] = useState('');
    const navigate = useNavigate();
    const { compte_id } = useParams();

    /*const requestForm = async () => {
        await axios.get('http://127.0.0.1:8000/api/approvisionement/create/')
        .then(({data}) => {
            console.log(data);
            setApproData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });
    }*/

    const request = async () => {
      try{
      await axios.get(`http://127.0.0.1:8000/api/produit/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setProduit(data);
      }).catch ((error) =>
      {
          setBackendErrorMessageProduit(error.response.data.error);
      });
    } catch (error) {
      console.log(error)
    }
  }

    useEffect(() => {
        request();
      }, []);


      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Effectuer la requête pour créer un nouvel approvisionnement
          await axios.post(`http://127.0.0.1:8000/api/sortieStock/create/${compte_id}`, {
            libelleProduit: selectedProduit,
            quantite: quantite,
          }).then(()=>{
            navigate('/ajout-produits-sorties')
          });
        }
        catch (error) {
          setBackendErrorMessageSortie(error.response.data.error);
        }
      };

    return (
        <div style={{backgroundColor: '#fcfcfc', }}>
        <Nav />
        <div className="container mt-5" style = {{maxWidth: "50%", background:  '#ffffff', borderradius: 10, padding: 20, boxShadow: '0 0 7px rgba(0, 123, 255, 0.5)'}}>
        <h3>Ajouter les produits</h3>
        <div style = {{color: 'grey', fontSize : '10px'}} >Les produits qui seront ajouter ici representent,<br></br>
            les produits de votre sortie actuel<br></br>
            </div>
            <div></div>
            <form  style = {{ borderRadius: 10, padding: 20, }} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="nom" className="form-label" style={{fontWeight: 600}}>Nom du produit</label>
                  <select
                          className="form-select"
                          id="nom"
                          value={selectedProduit}
                          onChange={(e) => setSelectedProduit(e.target.value)}
                        >
                          <option value="" disabled>
                            Sélectionnez un produit
                          </option>
                          {produit.map((produit) => (
                            <option key={produit.id} value={produit.nomProd} style={{color: '#000000'}}>
                              {produit.nomProd}
                            </option>
                          ))}
                          
                    </select>
                </div>
                <div className="mb-3">
                <label htmlFor="integer" className="form-label" style={{ fontWeight: 600 }}>
                      Quantite
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number"
                      value={quantite}
                      onChange={(e) => setQuantite(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Envoyer</button>
            </form>
            {backendErrorMessageSortie && <p style={{ color: 'red' }}>{backendErrorMessageSortie}</p>}
        </div>
        </div>
    );
}