import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../composants/nav.js';

export default function User() {
    
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');

    const request = async () => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/utilisateurs/`);
            console.log(response.data)
            setUtilisateurs(response.data);
        } catch (error) {
          if (error.response) {
            // Erreur provenant du serveur (backend)
            //
            setBackendErrorMessage(error.response.data.error);
            //console.log(setBackendErrorMessage);
             // Utilisez la structure de votre réponse d'erreur backend
        } else {
            // Erreur de réseau ou autre
            //console.error(error);
        }
        }
    }


    useEffect(() => {
        request();
    }, []);

    return (
        <div>
            <Nav />
            <h1>Liste des utilisateurs</h1>
            
            {Array.isArray(utilisateurs) ? (
            <ul>
                {utilisateurs.map(utilisateur => (
                    <li key={utilisateur.id}>{utilisateur.name} - {utilisateur.email}</li>
                ))}
            </ul>
            ) : (
              <p>{backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}</p>
          )}
        </div>
    );
}
