import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress ,Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import Fuse from 'fuse.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import m2agroup from '../images/m2a.png';
import B2 from '../images/B2.png';
import B from '../images/B.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';


const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var appro_Id=0;
  var BACKEND_URL = 'https://backend.bt-support.net/api/';
  var countData=1;
export default function Approvisionement() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { compte_id } = useParams();
  const [approData, setApproData] = useState([]);
  const [listCompteT, setListCompteT] = useState([]);
  const [approId, setApproId] = useState([]);
  const [libelleTreso, setlibelleTreso] = useState([]);
  const [nature, setNature] = useState([]);
  const [dateOpe, setDateOpe] = useState([]);
  const [montant, setMontant] = useState([]);


  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogSupprimer, setOpenDialogSupprimer] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [backendErrorMessage, setBackendErrorMessage] = useState('');


    const [open, setOpen] = React.useState(false);
    
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = approData.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(approData.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };



    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const action = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

 // Configuration de Fuse.js
 const fuseOptions = {
  keys: ['refAppro', 'libelleEntrepot','libelleFournisseur' , 'dateAppro'], // Les champs à rechercher
  includeScore: true,
};

// Initialiser un nouvel objet Fuse avec les options
const fuse = new Fuse(approData, fuseOptions);

// Fonction pour gérer le changement de la recherche
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // Effectuer la recherche et mettre à jour les résultats
  const results = fuse.search(query);

  if (query==''){
    //setApproData(results.map((result) => result.item));
    request()
  }
  else{
    setApproData(results.map((result) => result.item));
  }

};



    const request = async () => {
      try{
        await axios.get(`${BACKEND_URL}approvisionement/${compte_id}`)
        .then(({data}) => {
            console.log(data);
            setApproData(data);
        }).catch ((error) =>
        {
            setBackendErrorMessage(error.response.data.error);
        });

        await axios.get(`${BACKEND_URL}tresorerie/${compte_id}`)
      .then(({ data }) => {
        setListCompteT(data);
        console.log(listCompteT);
      }).catch((error) => {
        setBackendErrorMessage(error.response.data.error);
      });
    } catch (error) {
      console.log(error)
    }
    }

    const handleClickOpenDialog = (approId) => {
      setOpenDialog(true);
      setApproId(approId)
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleClickOpenDialogSupprimer = (approId) => {
      setOpenDialogSupprimer(true);
      setApproId(approId)
    };
  
    const handleCloseDialogSupprimer = () => {
      setOpenDialogSupprimer(false);
    };


    const reglementAppro = async (e) => {
      if(libelleTreso=='' || nature=='' || dateOpe=='' || montant==-1)
      {
        setBackendErrorMessage('Veuillez remplir tous les champs')
      }
      else
      {
        
        await axios.post(`${BACKEND_URL}approvisionement/reglement/${approId}/${compte_id}`, {
                          libelleTreso : libelleTreso,                          
                          nature: nature,
                          dateOpeTreso: dateOpe,
                          montant: montant
                }).then((data) =>{
                  request();
                  console.log(data)
                  setOpenDialog(false);
                  setBackendErrorMessage('')
                })
                .catch(error => {
                  console.error('Erreur de requête :', error);
                  setBackendErrorMessage(error.response.data.error)
      });
      }
      
    };

    const Deleterequest = async (appro_id) => {
      if (!window.confirm('Voulez-vous vraiment supprimer?')) {
        return;
      }    
      try {
        await axios.delete(`${BACKEND_URL}approvisionement/destroy/${appro_id}/${compte_id}`);
        console.log(`Approvisionnement avec l'ID ${appro_id} supprimé avec succès`);
        // Ajoutez ici la logique supplémentaire après la suppression
        setOpen(true);
        window.alert('Element supprimer avec succès')
        window.location.reload();        
      } catch (error) {
        console.error(`Erreur lors de la suppression de l\'approvisionnement ${appro_id} `, error);
        window.alert(error.response.data.error)
        // Ajoutez ici la gestion des erreurs
      }
  }

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
          
            const tableRows = approData.map((row, index) => (
              `<tr>
                <td>${index + 1}</td>
                <td>${row.refAppro}</td> 
                <td>${row.libelleEntre}</td> 
                <td>${row.nomFourni}</td> 
                <td>${row.valeurTotalAppro}</td> 
                <td>${row.name}</td> 
                <td>${format(new Date(row.dateAppro), 'dd-MM-yyyy')}</td>                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau des approvisionements</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">
                    <h1>M2AGROUP</h1>
                    <p>Siege social 01 BP 6727 OUAGA 01</p>
                    <p>Téléphone BF :+226 71081010</p>
                </div>

                  <h2>Tableau des approvisionements</h2>
                  <table>
                    <thead>
                      <tr>
                      <th>Numéro</th>
                      <th>Référence</th>
                      <th>Entrepot</th> 
                      <th>Fournisseur</th>                                                                                                                                 
                      <th>Valeur</th>   
                      <th>Enregistreur</th> 
                      <th>Date</th>
                       </tr>
                    </thead>
                    <tbody>
                      ${tableRows}
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
  };

  const handleExportExcel = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(approData);

    //XLSX.utils.sheet_add_aoa(ws, [['Relevée des sorties']], { origin: -1 });

    // Mettre la première ligne en gras
    //XLSX.utils.sheet_set_range_style(ws, { s: { font: { bold: true } }, e: { font: { bold: true } } });

    XLSX.utils.book_append_sheet(wb, ws , 'Approvisionement');

    XLSX.writeFile(wb , 'Relevé des approvisionements.xlsx');

  }


    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }        
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 2000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 200);

        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize', handleResize);
      };
      }, []);
      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if( !loading) {
    return (
      
      <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav 
              compte_id={compte_id}
            />
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
                <div style={{marginRight: 'auto'}}>
                  <h3>Gestions des approvisionements</h3>
                  </div>
                <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip>                
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => handlePrint()} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip>                
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => handleExportExcel()} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => handlePrint()} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => handlePrint()} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>                 
            </Stack> 
                            
                  <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 2, 
                      marginRight: 2, 
                      borderRadius: 5, 
                      minWidth:windowWidth-150,
                      boxShadow: '0px 0px 5px #241468',
                      backgroundColor:'white'}}>                  
                  <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
                    Liste des approvisionements
                  </Stack>

                  <Stack>
                      <div style={{marginBottom: 30}}>
                      <TextField type="text" 
                            value={searchQuery} 
                            onChange={handleSearchChange} 
                            placeholder="Rechercher...">
                      </TextField>
                      </div> 
                      { windowWidth > 800 ?
                    <TableContainer component={Paper}>
                            <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#241468',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Approvisionements</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Entrepot</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Fournisseur</TableCell>                                                                                                                                 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Valeur</TableCell>   
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Enregistreur</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date</TableCell> 
                                            <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                          
                                    </TableRow>                                    
                                        {data.map((row, index) => (
                                    <TableRow>
                                            <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.refAppro}</TableCell>     
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.libelleEntre}</TableCell>                                        
                                            <TableCell align="left" style={{fontWeight: 600}}>{row.nomFourni}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600,}}>{row.valeurTotalAppro}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600,}}>{row.name}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600,}}>{format(new Date(row.dateAppro), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell align="left" style={{fontWeight: 600}}>   
                                                  <Stack direction={'column'}
                                                      spacing={{sm: 1}}
                                                      alignItems={'left'}                                                   
                                                  >
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} >
                                                      <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={() => { navigate(`/modification-approvisionement/${row.id}/${compte_id}`) }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Procéder au règlement'>
                                                        <IconButton style={{ color: 'grey' }}  disabled onClick={() => { handleClickOpenDialog(row.id) }} >
                                                          <AttachMoneyIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={() => { navigate(`/afficher-approvisionement/${row.id}/${compte_id}`) }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => { /*Deleterequest(row.id)*/ 
                                                         appro_Id=row.id
                                                        console.log(appro_Id);
                                                        handleClickOpenDialogSupprimer()
                                                       }} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                   
                                                      </Stack>
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}}>                                                                                                       
                                                      </Stack>                                                    
                                                  </Stack>                                             
                                                                                             
                                            </TableCell>
                                                    </TableRow>
                                                      ))}
                                                </TableBody>
                            </Table>
                    </TableContainer>
      :
      <TableContainer component={Paper}>
                            <Table sx={{ minWidth:windowWidth-200,}} aria-label="simple table">
                                <TableHead style={{backgroundColor: '#241468',}}>
                                    <TableRow>
                                        <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Approvisionements</TableCell>
                                    </TableRow>
                                </TableHead>
                                {data.map((row, index) => (
                                <TableBody>
                                    <TableRow>                                            
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Référence</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Entrepot</TableCell> 
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.refAppro}</TableCell>     
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.libelleEntre}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>        
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Fournisseur</TableCell>                                                                                                                                 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Valeur</TableCell>  
                                    </TableRow>
                                    <TableRow>
                                          <TableCell align="center" style={{fontWeight: 600}}>{row.nomFourni}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600,}}>{row.valeurTotalAppro}</TableCell>                                            
                                    </TableRow>
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Enregistreur</TableCell> 
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Date</TableCell> 
                                   </TableRow>
                                   <TableRow>
                                   <TableCell align="center" style={{fontWeight: 600,}}>{row.name}</TableCell>
                                    <TableCell align="center" style={{fontWeight: 600,}}>{format(new Date(row.dateAppro), 'dd-MM-yyyy')}</TableCell>                                            
                                   </TableRow>
                                   <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>                                          
                                    </TableRow>                                        
                                    <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>   
                                                  <Stack direction={'column'}
                                                      spacing={{sm: 1}}
                                                      alignItems={'center'}                                                   
                                                  >
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}} >
                                                      <Tooltip title='Modifier'>
                                                        <IconButton style={{ color: '#40A2E3' }} onClick={() => { navigate(`/modification-approvisionement/${row.id}/${compte_id}`) }} >
                                                          <EditIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Procéder au règlement'>
                                                        <IconButton style={{ color: 'grey' }}  disabled onClick={() => { handleClickOpenDialog(row.id) }} >
                                                          <AttachMoneyIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Voir'>
                                                        <IconButton style={{ color: '#0D9276' }} onClick={() => { navigate(`/afficher-approvisionement/${row.id}/${compte_id}`) }} >
                                                          <ArrowForwardIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => { /*Deleterequest(row.id)*/ 
                                                         appro_Id=row.id
                                                        console.log(appro_Id);
                                                        handleClickOpenDialogSupprimer()
                                                       }} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                      </Tooltip>                                                   
                                                      </Stack>
                                                    <Stack direction={'row'}
                                                      spacing={{sm: 0}}>                                                                                                       
                                                      </Stack>                                                    
                                                  </Stack>                                             
                                                                                             
                                            </TableCell>
                                                    </TableRow>
                                                    </TableBody>
                                                      ))}
                                                
                            </Table>
                    </TableContainer>
      }
      
                    <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                        <nav > 
                            <ul className='pagination'>
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={prePage}>Préc</a>
                                </li>
                                {
                                  getPageNumbersToShow().map((n,i) =>
                                  (
                                    <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                      <a href='#' className='page-link'
                                      onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  )
                                  )
                                }
                                <li className='page-item'>
                                  <a href='#' className='page-link'
                                    onClick={nextPage}>Suiv</a>
                                </li>
                            </ul>
                        </nav>
                        <div>
                          <h5>Total : {approData.length}</h5> 
                        </div>
                </div>
                  </Stack>
                  <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => handleLinkClick(`formulaire-approvisionement/${compte_id}`)} >
                    Ajouter                      
                  </Button>
                  </Stack> 

                  <Dialog
                  fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du reglèment"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={listCompteT}
                              getOptionLabel={(option) => option.libelleTreso}
                              size='medium'
                              style={{minWidth:250}}
                             
                              /*style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}*/
                              value={libelleTreso.value}
                              onChange={(event, newValue) => {
                                setlibelleTreso(newValue ? newValue.libelleTreso : '');
                                console.log(newValue ? newValue.libelleTreso :''); // Nouvelle valeur sélectionnée
                                
                                //console.log(selectedFournisseur)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un compte" defaultValue={''} />
                              )}
                            />
                            <TextField type='text' 
                                label="Nature de l'opération"
                                value={nature}
                                style={{minWidth:230}}
                                select
                                defaultValue={''}
                                onChange={(e)=> {
                                  setNature(e.target.value)
                                }}
                            >
                                <MenuItem key={'Espèce'} value={'Espèce'}>
                                {'Espèce'}
                              </MenuItem>
                              <MenuItem key={'Chèque'} value={'Chèque'}>
                                {'Chèque'}
                              </MenuItem>
                              <MenuItem key={'Carte'} value={'Carte'}>
                                {'Carte'}
                              </MenuItem>
                            </TextField>
                          </Stack>
                          <Stack
                              direction={'row'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >  
                          <Stack direction={'column'}>
                              <label style={{fontWeight: 600}}>Montant</label>                           
                            <TextField type='number'                               
                                value={montant}
                                style={{minWidth:250}}
                                defaultValue={-1}
                                onChange={(e)=> {
                                  setMontant(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                            <Stack direction={'column'}>
                              <label style={{fontWeight: 600}}>Date</label>
                            <TextField type='date'                                 
                                value={dateOpe}
                                style={{minWidth:230}}
                                defaultValue={''}
                                onChange={(e)=> {
                                  setDateOpe(e.target.value)
                                }}
                            ></TextField>
                            </Stack>
                          </Stack>
                           
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                              reglementAppro()
                              }} style={{fontWeight: 600}}>
                              Valider 
                            </Button>
                            {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog> 


                <Dialog
                  fullScreen={fullScreen}
                  open={openDialogSupprimer}
                  onClose={handleCloseDialogSupprimer}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Suppression"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack>Voulez-vous vraiment supprimer cet approvisionement</Stack>
                    
                    {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                    

                  </DialogContent>
                  <DialogActions>
                  <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {
                    console.log(appro_Id)
                      Deleterequest(appro_Id);
                      }} style={{fontWeight: 600}}>
                      Valider 
                    </Button>
                    <Button variant='contained' color='error' onClick={handleCloseDialogSupprimer} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>                                    
                </div>

                

    );

    
    function nextPage(){
      if(firstIndex+10 < approData.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
          <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}
