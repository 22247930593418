import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../dasboard.css';
import axios from 'axios';
import {Button, Box, CircularProgress ,Stack, Tooltip, Container} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import openCom from '../images/openCom.png';
import carDelivery from '../images/carDelivery.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';


export default function AfficherCompte() {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { user_id,compte_id } = useParams();
  const [listCompte, setListCompte] = useState([]);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');


  const request = async () => {
    try{
    await axios.get(`${BACKEND_URL}comptes/${user_id}`)
    .then(({data}) => {
        console.log(data);
        setListCompte(data);
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
        console.log(error)
    });
  } catch (error) {
    console.log(error)
  }

}

const handleLinkClick = (link) => {
  // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
  // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
  navigate(`/${link}`)
};

  useEffect(() => {
    try {
      request();
      
    } catch (error) {
      console.log(error)
    }
    const timer = setTimeout(() => {
      // Mettez à jour l'état pour arrêter le chargement après 3 secondes
      setLoading(false);
    }, 3000); // 3000 millisecondes = 3 secondes
  
    // Nettoyez le timer lorsque le composant est démonté
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
    }, 300);
  
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
  };
  }, []);


  const [loading, setLoading] = useState(true);
const [currentImage, setCurrentImage] = useState(0);


  if(!loading) {
    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
          <Button variant="contained" style={{minWidth: 100, marginRight: 'auto'}} startIcon={<ArrowBackIcon />}   onClick={() => {handleLinkClick(`configurationSysteme/${compte_id}/Utilisateurs/${compte_id}`)}} >
                        RETOUR                      
                </Button>
            <Stack direction={'column'}
                     alignItems="center" // Centrer verticalement
                     justifyContent="center"
                     sx={{margin: 3}}>
                  <Stack sx={{fontWeight: 600, fontSize: 40}}>
                    Compte de l'utilisateur
                  </Stack>
                  <Stack
                      direction={ windowWidth>700?'row':'column'}
                      alignItems="center" // Centrer verticalement
                      justifyContent="center" // Centrer horizontalement
                      sx={{ margin: 2 }}
                      spacing={{ xs: 1, sm: 1, md: 4 }}
                    >
                      {listCompte.length === 0 ? (
                                        <Stack style={{fontWeight: 600, color: 'red', fontSize: 20}}>
                                          Cet utilisateur n'a pas encore de compte.
                                        </Stack>
                                      ) : (
                      listCompte.map((row, index) => (
                        <Stack>
                          <Tooltip title={`Compte du ${row.pays}`} onClick={() => { }}>
                            <Container
                              role='button'
                              sx={{
                                boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
                                minWidth: 120,
                                backgroundColor: '#BBE2EC',
                                height: 100,
                                borderRadius: 1.5,
                                display: 'flex'
                              }}
                            >
                              <Stack
                                direction={'column'}
                                alignItems="center"
                                sx={{ margin: 'auto', textAlign: 'center' }}
                              >
                                <Stack>
                                  <AccountCircleIcon fontSize='large' sx={{ color: '#3559E0' }} />
                                </Stack>
                                <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                  {row.pays}
                                </Stack>
                                <Stack sx={{ fontWeight: 600, color: '#00000' }}>
                                  {` Role : ${row.roleCompte}`}
                                </Stack>
                              </Stack>
                            </Container>
                          </Tooltip>
                        </Stack>
                      )))}
                    </Stack>
            </Stack>           
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}