import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import axios from 'axios';
import {CircularProgress , Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add.js';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import * as XLSX from 'xlsx'
import m2agroup from '../images/m2a.png';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];

  var BACKEND_URL = 'https://backend.bt-support.net/api/';


export default function AfficherSortie() {


    const [sortieData, setSortieData] = useState([]);
    const [opeSortieData, setOpeSortieData] = useState([]);
    const [listproduit, setListProduits] = useState([]);
    const { compte_id } = useParams();
    
    const [open, setOpen] = React.useState(false);
    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = listproduit.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(listproduit.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = 5;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };

    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { sortie_id } = useParams();

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const action = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleClose}>
          UNDO
        </Button>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const handlePrint = () => {
      const printWindow = window.open('', '_blank');
          
            const tableRows = listproduit.map((row, index) => (
              `<tr>                              
                <td>${row.libelleProduit}</td> 
                <td>${row.quantite.toLocaleString('fr-FR')}</td> 
                <td>${row.prixVente== null? '0': row.prixVente.toLocaleString('fr-FR')}</td>  
                <td>${row.valeur.toLocaleString('fr-FR')}</td>                         
              </tr>`
            )).join('');
            
            // Utilisez la chaîne HTML générée pour construire le document HTML
            const htmlContent = `
              <html lang="fr">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Tableau détails sorties</title>                
                  <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: center; }
                    th { background-color: #f2f2f2; }
                    .header {
                      border-bottom: 2px solid #000;
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                  }
                  .header img {
                      width: 200px;
                      height: auto;
                      float: left;
                      margin-right: 20px;
                  }
                  .header h1,
                  .header p {
                      margin: 0;
                      padding: 0;
                  }
                  /* Aligne les éléments pour l'impression */
                  .header h1 {
                      font-size: 24px;
                      line-height: 1.2;
                  }
                  .header p {
                      font-size: 14px;
                      line-height: 1.4;
                  }
                  </style>
                </head>
                <body>
                <div class="printable header">
                    <img src=${m2agroup} alt="Logo">
                    <h1>M2AGROUP</h1>
                    <p>Siege social 01 BP 6727 OUAGA 01</p>
                    <p>Téléphone BF :+226 71081010</p>
                </div>
                  <h2>Tableau détails sorties</h2>
                  <table>
                    <thead> 
                    <tr >
                      <td colspan="4">${sortieData.refReleveSortie}</td>
                    </tr>  
                    <tr >
                      <td colspan="2" style='border-right: none'>Client: ${sortieData.nomClient}</td>
                      <td colspan="1" style='border: none'>Entrepot : ${sortieData.libelleEntre}</td>
                      <td colspan="1" style='border-left: none'>Type de sortie : ${sortieData.typeSortie}</td>
                    </tr>                      
                    </thead>
                    <tbody>
                    <tr>
                      <td>Produit</td>
                      <td>Quantité</td>
                      <td>Prix vente</td>
                      <td>Valeur</td>
                    </tr>                     
                      ${tableRows}
                      <tr >
                          <td colspan="2" style='border-right: none'>Valeur totale : ${sortieData.valeurTotalSortie.toLocaleString('fr-FR')}</td>
                          <td colspan="2" >Solde restant à payer: ${sortieData.soldeRestantAPaye.toLocaleString('fr-FR')}</td>
                      </tr>                                          
                    </tbody>
                  </table>
                </body>
              </html>`;
            
            // Utilisez document.write pour écrire le contenu HTML dans la fenêtre d'impression
            printWindow.document.write(htmlContent);
            printWindow.document.close();
            printWindow.print();
    };

const request = async () => {
try{
    await axios.get(`${BACKEND_URL}sortieStock/${sortie_id}/${compte_id}`)
    .then(({data}) => {
        setListProduits(data);
        console.log(listproduit);
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
    });

    await axios.get(`${BACKEND_URL}releveSortie/show/${sortie_id}/${compte_id}`)
    .then(({data}) => {
        setSortieData(data);
        console.log(sortieData);
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
    });

    await axios.get(`${BACKEND_URL}operationTresorerie/sortie/${sortie_id}/${compte_id}`)
    .then(({data}) => {
        setOpeSortieData(data);
        console.log(opeSortieData);
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
    });
  } catch (error) {
    console.log(error)
  }
}
    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 3000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 300);

        return () => {
          clearTimeout(timer);
          clearInterval(interval)
      };
      
      }, []);
      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);

      if( !loading) {
    return (
        <div style={{backgroundColor: '#fffcfc', paddingTop: 20, paddingLeft: 20, paddingBottom: 20}}>
            <Stack direction={'row'}
                    alignItems='center'
                    spacing={{}}
                     sx={{margin: 3}}>
            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}}  onClick={() => {handleLinkClick(`sorties/${compte_id}`)}} >
                    RETOUR                      
            </Button>
            <Tooltip title="Imprimer">
                    <IconButton onClick={() => handlePrint()} >
                        <PrintIcon style={{color: 'blue'}}/>                    
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en PDF">
                    <IconButton onClick={() => {}} >
                        <PictureAsPdfIcon  style={{color: 'red'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en EXCEL">
                    <IconButton onClick={() => {}} >
                        <TableChartIcon style={{color: 'green'}}/>                     
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Exporter en CSV">
                    <IconButton onClick={() => {}} >
                        <DescriptionIcon style={{color: '#2D9596'}} />                     
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copier">
                    <IconButton onClick={() => {}} >
                        <FileCopyIcon style={{color: 'black'}}/>                     
                    </IconButton>
                </Tooltip>
            </Stack>
            <Stack direction={'column'}
                    alignItems='center'
                    sx={{marginTop: 3,
                      marginLeft: 15, 
                      marginRight: 15, 
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                      boxShadow: '0px 0px 5px #332941',
                      backgroundColor:'white'}}> 
                            <Stack>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                      <TableHead style={{backgroundColor: '#332941',}}>
                                        <TableRow>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={4}>{sortieData.refReleveSortie}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600}} colSpan={2}>Client : {sortieData.nomClient}</TableCell>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600}} >Entrepot : {sortieData.libelleEntre}</TableCell>
                                            <TableCell align="center" style={{color: 'white', fontWeight: 600}} >Type de sortie : {sortieData.typeSortie}</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        
                                        <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Produit</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Quantité</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Prix de vente</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Valeur</TableCell>
                                        </TableRow>
                                      {listproduit.map((row, index) => (
                                        <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.libelleProduit}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.quantite}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.prixVente.toLocaleString('fr-FR')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600}}>{row.valeur.toLocaleString('fr-FR')}</TableCell>
                                        </TableRow>
                                      ))}
                                      <TableRow>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#332941', color: 'white'}} colSpan={2}>Valeur totale sortie: {sortieData.valeurTotalSortie.toLocaleString('fr-FR')}</TableCell>
                                            <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#332941', color: 'white'}} colSpan={2}>Reste à payer:  <a href='#' style={{color: 'red'}}>{sortieData.soldeRestantAPaye.toLocaleString('fr-FR')}</a></TableCell>
                                      </TableRow>
                                      </TableBody>
                                    </Table>
                                </TableContainer> 
                                <div class="flex-row" style={{marginTop: 10, marginBottom: 10}}>
                                    <nav style={{marginRight: 'auto'}}> 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div style={{marginLeft: 'auto'}}>
                                      <h5>Total produits:  {listproduit.length}</h5> 
                                    </div>
                                </div>      
                            </Stack>
                        <Stack>
                            <TableContainer component={Paper} style={{marginTop: 20}}>
                              <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#607274', }}>
                                  <TableRow>
                                    <TableCell align="center" style={{ color: 'white', fontWeight: 600, fontSize: 20 }} colSpan={8}>Opérations</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Numéro</TableCell>
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Compte trésorerie</TableCell>
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Type d'opération</TableCell>                                    
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Date</TableCell>
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Montant</TableCell>
                                    <TableCell align="center" style={{ fontWeight: 600, backgroundColor: '#F6F5F5' }} >Solde après</TableCell>
                                  </TableRow>
                                  {opeSortieData.map((row, index) => (
                                    <TableRow>
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{index + 1}</TableCell>
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{row.libelleTreso}</TableCell>
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{row.typeOperation}</TableCell>                                                          
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{row.dateOpeTreso}</TableCell>
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{row.montant.toLocaleString('fr-FR')}</TableCell> 
                                      <TableCell align="center" style={{ fontWeight: 600 }}>{row.soldeApresOpert.toLocaleString('fr-FR')}</TableCell>                                        
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <div class="flex-row" style={{marginTop: 10, marginBottom: 10}}>
                                    <nav style={{marginRight: 'auto'}}> 
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={prePage}>Préc</a>
                                            </li>
                                            {
                                              getPageNumbersToShow().map((n,i) =>
                                              (
                                                <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                                  <a href='#' className='page-link'
                                                  onClick={() => changeCPage(n)}>{n}</a>
                                                </li>
                                              )
                                              )
                                            }
                                            <li className='page-item'>
                                              <a href='#' className='page-link'
                                                onClick={nextPage}>Suiv</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div style={{marginLeft: 'auto'}}>
                                      <h5>Total opérations:  {opeSortieData.length}</h5> 
                                    </div>
                                </div>
                      </Stack> 
                  </Stack>
        </div>
    );

    function nextPage(){
      if( currentPage !== lastIndex)
      {
        setCurrentPage(currentPage + 1);
      }
    }

    function prePage(){
      if(currentPage !== firstIndex)
      {
        setCurrentPage(currentPage - 1);
      }
    }

    function changeCPage(id){
      setCurrentPage(id);
    }
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress />
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}

