import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import { useNavigate ,useParams} from "react-router-dom";
import axios from 'axios';
import ajouter from '../images/ajouter.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';


var BACKEND_URL = 'https://backend.bt-support.net/api/';

export default function AjoutProdSortie() {

        
    const [sortieData, setSortieData] = useState([]);
    const [lastIdSortie, setLastIdSortie] = useState([]);
    const [produit, setProduit] = useState([]);
    const [selectedProduit, setSelectedProduit] = useState('');
    const [selectedQuantite, setSelectedQuantite] = useState('');
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const { compte_id } = useParams();


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

    const request =  async () => {
      try {
        const response = await axios.get(`http://127.0.0.1:8000/api/releveSortie/${compte_id}`);
        const data = response.data;
    
        console.log(data); // Affichez l'ensemble des données pour vérifier sa structure
    
        if (data && data.length > 0) {
          const lastEntry = data[data.length - 1]; // Obtenez la dernière entrée du tableau
          const lastEntryId = lastEntry.id; // Obtenez l'ID de la dernière entrée
    
          console.log('Dernière ID de la réponse :', lastEntryId);
    
          setLastIdSortie(lastEntryId);
          await axios.get(`http://127.0.0.1:8000/api/sortieStock/${lastEntryId}/${compte_id}`)
          .then(({data}) => {
              console.log(data);
              setSortieData(data);
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
        } else {
          console.log('Aucune donnée disponible.');
        }
      } catch (error) {
        setBackendErrorMessage(error.response?.data?.error || 'Une erreur s\'est produite lors de la récupération des données.');
      }
    }


    const Deleterequest = async (sortieId) => {
        try {
            await axios.delete(`http://127.0.0.1:8000/api/sortieStock/destroy/${sortieId}/${compte_id}`);
            console.log('La sortie a été supprimée avec succès.');
          } catch (error) {
            setBackendErrorMessage(error.response.data.error);
          }
    }

    useEffect(() => {
        request(lastIdSortie);
      }, []);

    return (
        <div style={{backgroundColor: '#fcfcfc', }}>
          
            <div className="container mt-4 d-flex justify-content-center">
            <div class="flex-row">
                  <div>
                  <h2>Produit de la sortie</h2> 
                  </div>
                  <div style={{ width: '50px', height: '50px' }}>
                          <a href='' onClick={() => handleLinkClick('formulaire-produits-sorties')}> 
                              <img src={ajouter}
                                width= '100%' 
                                height='100%'
                              />
                          </a>
                  </div>
                </div>
                
            <table class="table" style={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)'}}>
                  <thead>
                    <tr>
                      <th scope="col">Numéro</th>
                      <th scope="col">Produit</th>
                      <th scope="col">Quantite</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {Array.isArray(sortieData) ? (
                  <tbody>
                  {sortieData.map((row, index) => (
                    <tr>
                      <th scope="row">{index +1}</th>
                      <td>{row.libelleProduit}</td>
                      <td>{row.quantite}</td>
                      <td>
                        <div class="flex-row">
                            <div role='button' style={{backgroundColor: '#fcfcfc'}} onClick={() => Deleterequest(index)}>
                                <FontAwesomeIcon icon={faCreditCard} style={{ color: 'rgba(0, 123, 255, 0.5)' }} />
                            </div>
                        </div>
                      </td>
                    </tr>
                      ))}
                  </tbody>
                   ) : (
                    <p>{backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}</p>
                )}
                </table>
                <button  onClick={() => handleLinkClick('sorties')}  class="btn btn-info text-white">
                  Valider la vente
                </button>
                </div>
        </div>
    );
}