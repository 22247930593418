import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import ajouter from '../images/ajouter.png';
import background from '../images/bg3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {MenuItem,CircularProgress,Box, TextField, Stack, Fab, Autocomplete , Container, Tooltip , IconButton, Button} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add.js';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import B from '../images/B.png';
import B2 from '../images/B2.png';
import H from '../images/H.png';
import hand from '../images/hand.png';
import laptop from '../images/laptop.png';
import lock from '../images/lock.png';
import T from '../images/T.png';
import techni from '../images/techni.png';
import wallet from '../images/wallet.png';

const loadingImages = [
     B,
     T,
     techni,
     laptop,
     lock,
     hand,
     B2,     
     wallet
    // Ajoutez autant d'images que nécessaire
  ];


var nextId = 0;
var BACKEND_URL = 'https://backend.bt-support.net/api/';

export default function ModifierFormProdSortie() {


    const [entrepots, setEntrepots] = useState([]);
    const [selectedEntrepot, setSelectedEntrepot] = useState('');
    const [client, setClient] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [produit, setProduit] = useState([]);
    const [selectedProduit, setSelectedProduit] = useState('');
    const [selectedTypeSortie, setSelectedTypeSortie] = useState('')
    const [prixAchat, setPrixAchat] = useState([]);
    const [quantite, setQuantite] = useState([]);
    const [releveSortie, setReleveSortie] = useState([]);
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [focused2, setFocused2] = useState(false);
    const { compte_id } = useParams();
    
    const [listproduit, setListProduits] = useState([]);
    const { releveSortie_id } = useParams();
    
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [backendErrorMessageProduit, setBackendErrorMessageProduit] = useState('');
    const [backendErrorMessageSortie, setBackendErrorMessageSortie] = useState('');
    const [width, setWindowWidth] = useState(0);


    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};


    const handleProduitChange = (newValue) => {
      setSelectedProduit(newValue);
    };
    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    };
    

    const request = async () => {
      try{
      await axios.get(`${BACKEND_URL}entrepots/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setEntrepots(data);

      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}client/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setClient(data);
      }).catch ((error) =>
      {
          setBackendErrorMessage(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}produit/${compte_id}`)
      .then(({data}) => {
          console.log(data);
          setProduit(data);
      }).catch ((error) =>
      {
          setBackendErrorMessageProduit(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}sortieStock/${releveSortie_id}/${compte_id}`)
      .then(({data}) => {
          setListProduits(data);
          console.log(listproduit);
      }).catch ((error) =>
      {
          setBackendErrorMessageProduit(error.response.data.error);
      });

      await axios.get(`${BACKEND_URL}releveSortie/show/${releveSortie_id}/${compte_id}`)
      .then(({data}) => {
          setReleveSortie(data);
          console.log(releveSortie);
      }).catch ((error) =>
      {
          setBackendErrorMessageProduit(error.response.data.error);
      });
    } catch (error) {
      console.log(error)
    }

  }

    useEffect(() => {
      try {
        request();
        
      } catch (error) {
        console.log(error)
      }
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        const timer = setTimeout(() => {
          // Mettez à jour l'état pour arrêter le chargement après 3 secondes
          setLoading(false);
        }, 5000); // 3000 millisecondes = 3 secondes
    
        // Nettoyez le timer lorsque le composant est démonté
        const interval = setInterval(() => {
          setCurrentImage((prevImage) => (prevImage + 1) % loadingImages.length);
        }, 300);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
          window.removeEventListener('resize',updateDimensions);
      };

      }, []);

      const [loading, setLoading] = useState(true);
      const [currentImage, setCurrentImage] = useState(0);


      //selectedEntrepot = approvisionement.libelleEntrepot;
      //selectedClient = approvisionement.libelleclient;
      //selectedDate= approvisionement.dateAppro;


      const handleSubmit = async (e) => {
        
        //e.preventDefault();
        try {
        
          // Effectuer la deuxième requête pour chaque produit dans listproduit
          for (const produit of listproduit) {
            await axios.post(`${BACKEND_URL}sortieStock/updateStockInSortie/${releveSortie_id}/${compte_id}`, {
              libelleProduit: produit.libelleProduit,
              quantite: produit.quantite,
            });
          }
        
          console.log('Toutes les requêtes ont été effectuées avec succès');
          navigate(`/sorties/${compte_id}`);

        } catch (error) {
          console.error('Une erreur s\'est produite :', error);
          setBackendErrorMessage(error.response.data.error);
        }
      };

      const Deleterequest = async (sortie_id) => {
        if (!window.confirm('Voulez-vous vraiment supprimer?')) {
          return;
        }    
        try {
          await axios.delete(`${BACKEND_URL}sortieStock/destroy/${sortie_id}/${compte_id}`);
          console.log(`Sortie avec l'ID ${sortie_id} supprimé avec succès`);
          // Ajoutez ici la logique supplémentaire après la suppression
          //setOpen(true);
          request();
          window.alert('Element supprimer avec succès')
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'approvisionnement', error);
          window.alert(error.response.data.error)
          // Ajoutez ici la gestion des erreurs
        }
    }


    if(!loading) {
    return (
        <div style={{
              //backgroundColor: '#EFECEC', 
              backgroundImage: `url(${background})` ,
              backgroundSize: 'cover',
              }}>
        <Nav compte_id={compte_id} />
        <Button variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight: 'auto'}}   onClick={() => {handleLinkClick(`sorties/${compte_id}`)}} >
                    RETOUR                      
            </Button>
        <div className="container mt-5" style = {{maxWidth: "80%", backgroundColor:  '', borderRadius: 20, }}>
        <div className='row'>
        <div className="col-sm-6" style = {{borderTopLeftRadius: 20 ,borderEndStartRadius: 20 , backgroundColor:  '#ffffff', padding: 20}}>
            <Stack direction={'column'}
                 alignItems="center"
                 spacing={{ xs: 1, sm: 1, md: 4 }}>
                  <Stack>
                      <Container>
                        <h4>Informations relevée sortie</h4>
                      </Container>
                  </Stack>                                    
                  <Stack direction={'column'} spacing={1}>
                  <Container >
                    <label htmlFor="nom" className="form-label" style={{fontWeight: 600}}>Libellé de l'entrepot</label>
                    <Autocomplete
                        id="combo-box-demo"
                        options={entrepots}
                        getOptionLabel={(option) => option && option.libelleEntre ? option.libelleEntre : ''}
                        style={{width: width<800 ?  250 : (width*30)/100}}
                        value={selectedEntrepot.value}
                        disabled
                        isOptionEqualToValue={(option, value) => option.libelleEntre === value}
                        onChange= {(event, newValue) => {
                          setSelectedEntrepot(newValue ? newValue.libelleEntre:'');
                          console.log(newValue ? newValue.libelleEntre:'');
                          // Nouvelle valeur sélectionnée
                        }
                      }
                        renderInput={(params) => (
                          <TextField {...params} label={focused ? "Sélectionnez un entrepôt" : `${releveSortie.libelleEntre}`}
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)} />
                        )}
                      />
                  </Container>
                  <Container >
                    <label htmlFor="text" className="form-label"  style={{fontWeight: 600}}>Libellé client</label>
                    <Autocomplete
                        id="combo-box-demo"
                        options={client}
                        getOptionLabel={(option) => option.nomClient}
                        style={{width: width<800 ?  250 :(width*30)/100}}
                        value={selectedClient.value}
                        disabled
                        onChange={(event, newValue) => {
                          setSelectedClient(newValue ? newValue.nomClient:'');
                          console.log(newValue ? newValue.nomClient:''); // Nouvelle valeur sélectionnée
                          
                          console.log(selectedEntrepot) 
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label={focused1 ? "Sélectionnez un client" : `${releveSortie.nomClient}`}
                          onFocus={() => setFocused1(true)}
                          onBlur={() => setFocused1(false)} />
                        )}
                      />
                    </Container>
                    <Container >
                    <label htmlFor="text" className="form-label"  style={{fontWeight: 600}}>Type de la sortie</label>
                      <TextField type='text' 
                            label={releveSortie.typeSortie}
                            value={selectedTypeSortie}
                            style={{width: width<800 ?  250 : (width*30)/100}}
                            select
                            defaultValue={releveSortie.typeSortie}
                            disabled
                            onChange={(e)=> {
                              setSelectedTypeSortie(e.target.value)
                            }}
                        >
                            <MenuItem key={'Vente'} value={'Vente'}>
                            {'Vente'}
                          </MenuItem>
                          <MenuItem key={'Casse'} value={'Casse'}>
                            {'Casse'}
                          </MenuItem>
                        </TextField>
                      </Container>                    
                    <Container>
                      <Stack direction={'column'} spacing={1}>
                              <label htmlFor="date" className="form-label" style={{ fontWeight: 600 }}>
                              Date
                            </label>
                            <TextField type='date' 
                            value={releveSortie.dateSortie}
                            disabled
                            style={{width: width<800 ?  250 :(width*30)/100}}
                            onChange={(e) => {
                              setSelectedDate(e.target.value)
                              console.log(quantite)
                            }
                            }
                            label={focused2 ? "Sélectionnez une date" : ''}
                          onFocus={() => setFocused2(true)}
                          onBlur={() => setFocused2(false)} 
                            >

                            </TextField>
                      </Stack>                    
                    </Container>
                  </Stack>
                  <Button variant="contained" endIcon={<SaveIcon />} onClick={() => {handleSubmit()}} disabled={!listproduit.length > 0}>
                    Enregistrer
                  </Button>
                </Stack>
                </div>
                <div className="col-sm-6" style={{backgroundColor: '#0D9276', borderTopRightRadius: 20 ,borderEndEndRadius: 20 ,padding: 20}}>
                <Stack direction={'column'}
                 alignItems="center"
                 spacing={{ xs: 1, sm: 2, md: 4 }}>
                  <Stack>
                  <Container>
                      <h4 style={{color:'white'}}>Informations produits</h4>
                  </Container>
                  </Stack>
                  <Stack direction={'column'} spacing={1}>            
                      <Container >
                      <label htmlFor="nom" className="form-label" style={{fontWeight: 600, color: 'white'}}>
                      Libellé du produit</label>
                          <Autocomplete
                              id="combo-box-demo"
                              options={produit}
                              getOptionLabel={(option) => option.nomProd}
                              size='medium'
                              style={{width: width<800 ?  250 :(width*30)/100,
                              backgroundColor: '#ffffff'}}
                              value={selectedProduit.value}
                              onChange={(event, newValue) => {
                                setSelectedProduit(newValue ? newValue.nomProd:'');
                                console.log(newValue ? newValue.nomProd:''); // Nouvelle valeur sélectionnée
                                
                                console.log(selectedClient)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Sélectionnez un produit"  />
                              )}
                            />
                      </Container>
                  </Stack>

                    <Stack direction={'column'} spacing={1}>
                          <label htmlFor="integer" className="form-label" style={{ fontWeight: 600 , color: 'white'}}>
                                  Quantité
                          </label>
                          <TextField
                              type="number"
                              className="form-control"
                              id="number"
                              style={{width: width<800 ?  250 :(width*30)/100}}
                              value={quantite}
                              onChange={(e) => {
                                setQuantite(e.target.value)
                              
                              console.log(selectedProduit)
                              }
                              }
                            />
                    </Stack>
                  <Stack>
                      <Fab variant="extended" size="medium" color="primary"
                        onClick={() => {
                        setListProduits([
                          ...listproduit,
                          {id: nextId++, libelleProduit: selectedProduit, quantite: quantite }
                        ]);
                        console.log(listproduit) }}
                        style={{width: width<800 ?  250 :(width*15)/100}}>
                          <AddIcon sx={{ mr: 1 }} />
                          Ajouter
                      </Fab>
                  </Stack>
                </Stack>
                <div className="container mt-4 d-flex justify-content-center" >
                      <div>
                      <h5 style={{color: 'white'}}>Produits</h5> 
                      </div>
                
                    <table class="table" style={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)'}}>
                          <thead>
                            <tr >
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Numéro</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Produit</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Quantité</th>
                              <th scope="col" style={{backgroundColor: '#12372A', color: 'white'}}>Action</th>
                            </tr>
                          </thead>
                          {Array.isArray(listproduit) ? (
                          <tbody>
                          {listproduit.map((row, index) => (
                            <tr>
                              <th scope="row" style={{backgroundColor: '#ffffff', fontWeight: 600}}>{index + 1 }</th>
                              <td style={{backgroundColor: '#ffffff', fontWeight: 600}}>{row.libelleProduit}</td>
                              <td style={{backgroundColor: '#ffffff', fontWeight: 600}}>{row.quantite}</td>                
                                 <td style={{backgroundColor: '#ffffff', fontWeight: 600}}>  
                                <Tooltip title="Delete">
                                    <IconButton style={{ color: '#D24545' }} onClick={() => {
                                      //console.log(row.id)
                                           Deleterequest(row.id)
                                          }}>                                        
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                              </td>
                                                              
                            {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}
                            </tr>
                              ))}
                          </tbody>
                           ) : (
                            <p>{backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>}</p>
                        )}
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
  }
  else
  {
      return(
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // 100% de la hauteur de la vue
          }}
        >
          <Stack direction={'column'} alignItems={'center'} spacing={1}>
              <CircularProgress/>
              <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
          </Stack>
        </Box>
      );
  }
}