import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from '../../composants/nav.js';
import '../dasboard.css';
import {Stack, Tooltip, Container} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';
import LabelIcon from '@mui/icons-material/Label';
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import WorkIcon from '@mui/icons-material/Work';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import openCom from '../images/openCom.png';
import carDelivery from '../images/carDelivery.png';

export default function ConfigurationStock() {
  const navigate = useNavigate();
  const { compte_id } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);

      return () => {

        window.removeEventListener('resize', handleResize);
    };
    }, []);

    return (
        <div style={{backgroundColor: '#fcfcfc', height: '100vh',width: '100%',}}>
            <Nav compte_id={compte_id}/>
            <Stack direction={'column'}
                     sx={{margin: 3}}>
                  <Stack sx={{fontWeight: 600, fontSize: 20}}>
                    Configuration des produits
                  </Stack>
                  <Stack direction={windowWidth>700?'row':'column'}
                        alignItems="right"
                        sx={{margin: 2}}
                        spacing={{ xs: 1, sm: 1, md: 4 ,}}>

                          <Stack>
                            <Tooltip title='Type de produit'  onClick={()=> {navigate(`type-produits/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)' ,minWidth:120, minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <CategoryIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Type
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Marque' onClick={()=> {navigate(`marque-produits/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)'  ,minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <LabelIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Marque
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Modèle'  onClick={()=> {navigate(`modele-produits/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)' , minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <DescriptionIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Modèle
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Produit'  onClick={()=> {navigate(`produits/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)', minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <ShoppingCartIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Produit
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                  </Stack>
                  <Stack sx={{fontWeight: 600, fontSize: 20}}>
                    Autres configurations
                  </Stack>
                  <Stack direction={windowWidth>700?'row':'column'}
                        alignItems="right"
                        sx={{margin: 2}}
                        spacing={{ xs: 1, sm: 1, md: 4 ,}}>

                          <Stack>
                            <Tooltip title='Entrepot'  onClick={()=> {navigate(`entrepots/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)' , minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <StorefrontIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Entrepot
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Fournisseurs' onClick={()=> {navigate(`fournisseurs/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)' ,minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <LocalShippingIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Fournisseur
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          <Stack>
                            <Tooltip title='Commerciaux'  onClick={()=> {navigate(`commerciaux/${compte_id}`)}}>
                              <Container role='button' sx={{boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)' , minWidth:120,backgroundColor: '#BBE2EC', height: 100, borderRadius: 1.5, display: 'flex'}}>
                                <Stack direction={'column'}
                                  alignItems="center"
                                 sx={{ margin: 'auto', textAlign: 'center',}}
                                  >
                                    <Stack>
                                      <WorkIcon fontSize='large' sx={{ color: '#3559E0' }}/>
                                    </Stack>
                                    <Stack sx={{fontWeight: 600, color:'#00000'}}>
                                      Commerciaux
                                    </Stack>
                                </Stack>
                              </Container>
                            </Tooltip>
                          </Stack>
                          
                  </Stack>
            </Stack>
           
        </div>
    );
}